<template>
  <div
    class="sidebar-right fixed top-0 right-0 bg-white shadow-lg w-full max-w-md h-screen overflow-auto"
  >
    <div class="px-4 pt-2">
      <h3>Vad innebär livssituation?</h3>
      <p class="py-2">
        Här besvarar du frågor om din livssituation och delar dem med din
        vuxenpsykiatriska mottagning.<br />
        Din livssituation motsvarar Vuxenpsykiatrin i Hallands egenremiss. Genom
        att du beskriver den här kan vi bättre och snabbare förstå dina behov.
      </p>
      <p class="font-semibold py-2">
        OBS! Om du har akuta besvär, ring din mottagning eller 1177 Vårdguiden
        på telefonnummer 1177.
      </p>
      <p class="py-2">
        Du lämnar uppgifter som hjälper oss att bedöma vilken vård som är bäst
        för dig. Endast de behandlare som ansvarar för din behandling kan se
        uppgifterna.
      </p>
      <p class="py-2">
        Inloggningen är personlig. Du kan bara utföra ärenden som gäller dig
        själv. Besvara frågorna så tydligt du kan.
      </p>
    </div>
    <div class="mt-16 absolute text-center w-full h-20">
      <BaseFlatButton type="left" @click.native="close">{{
        $t("button.close")
      }}</BaseFlatButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarRight",
  mounted() {
    this.$el.style.display = "none";
    this.$el.style.right = `-${this.$el.offsetWidth + 10}px`;
  },
  computed: {
    open() {
      return this.$store.getters.isSidebarRightOpen;
    },
  },
  watch: {
    open: function (open) {
      if (open) {
        this.$el.style.display = "block";
        this.$el.style.right = "0";
        this.$store.dispatch("setSidebarRightShown");
      } else {
        this.$el.style.right = `-${this.$el.offsetWidth + 10}px`;
      }
    },
  },
  methods: {
    close() {
      this.$store.dispatch("closeSidebarRight");
    },
  },
};
</script>

<style>
.sidebar-right {
  transition: 0.3s;
}
</style>