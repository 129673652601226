<template>
  <div class="w-auto mx-4 px-4 text-center rounded-lg bg-white">
    <button class="my-2">
      <div class="flex h-4 items-center">
        <div v-if="type === 'left'" class="justify-start">
          <img
            class="inline align-text-bottom"
            height="16px"
            src="/images/cheveron-left.png"
          />
        </div>

        <div class="flex-1">
          <slot></slot>
        </div>

        <div v-if="type === 'right'" class="justify-end">
          <img
            class="inline align-text-bottom"
            height="16px"
            src="/images/cheveron-right.png"
          />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "BaseFlatButton",
  props: {
    type: {
      type: String,
      default: "right",
    },
  },
};
</script>
