export default {
    sv: [
        {
            q: 'Hur behandlas mina personuppgifter?',
            a: "Vi värnar om dina personuppgifter, och behandlar dessa i enlighet med Region Hallands Behandling av personuppgifter. Läs mer på <a target='_blank' href='http://regionhalland.se/om-region-halland/organisation/dataskydd/'>http://regionhalland.se/om-region-halland/organisation/dataskydd/</a>"
        },
    ],
    en: [
        {
            q: 'Hur behandlas mina personuppgifter?',
            a: "Vi värnar om dina personuppgifter, och behandlar dessa i enlighet med Region Hallands Behandling av personuppgifter. Läs mer på <a target='_blank' href='http://regionhalland.se/om-region-halland/organisation/dataskydd/'>http://regionhalland.se/om-region-halland/organisation/dataskydd/</a>"
        },
    ]
}