<template>
  <div>
    <input
      v-on:keyup.enter="valid ? $emit('submit') : null"
      type="number"
      @keyup="filterValue"
      :maxlength="max"
      :placeholder="placeholder"
      class="w-full max-w-xs rounded-lg border border-grey-light px-2 py-2 text-center text-xl tracking-normal focus:outline-none"
      :class="validityClass"
      v-model="filteredSsn"
    />
  </div>
</template>

<script>
import { SwedishSsnValidator } from "../../utils/validators.js";

export default {
  name: "BaseInputSsn",
  props: ["value"],
  data() {
    return {
      ssn: null,
      filteredSsn: null,
      century: false,
      valid: null,
    };
  },
  watch: {
    filteredSsn: {
      // immediate: true,
      handler() {
        this.$emit("input", this.computedNationalId);
      },
    },
    valid(value) {
      this.$emit("validationStatus", value);
    },
  },
  methods: {
    filterValue(e) {
      let value = e.target.value.replace("-", "");

      this.filteredSsn = value.replace(/D/g, "");

      if (this.filteredSsn.length > 2) {
        this.century =
          ["19", "20"].indexOf(this.filteredSsn.substr(0, 2)) >= 0
            ? true
            : false;
      }

      if (
        this.filteredSsn.length == (this.century ? 8 : 6) &&
        e.keyCode !== 8
      ) {
        this.filteredSsn = `${this.filteredSsn.substr(
          0,
          this.century ? 8 : 6
        )}${this.filteredSsn.substr(this.century ? 8 : 6)}`;
      }

      if (
        this.filteredSsn.length > (this.century ? 8 : 6) &&
        this.filteredSsn.indexOf("-") == -1
      ) {
        this.filteredSsn = `${this.filteredSsn.substr(
          0,
          this.century ? 8 : 6
        )}${this.filteredSsn.substr(this.century ? 8 : 6)}`;
      }

      this.ssn = this.filteredSsn.replace(/D/g, "");

      this.valid = SwedishSsnValidator(
        this.century ? this.ssn.substr(2) : this.ssn
      );
    },
  },
  computed: {
    computedNationalId() {
      if (!this.century && this.filteredSsn.length == 10) {
        let d = new Date();
        let year = d.getFullYear().toString();
        year = parseInt(year.substr(2));
        let birthYear = parseInt(this.filteredSsn.substr(0, 2));
        if (birthYear > year) {
          return "19" + this.filteredSsn;
        } else {
          return "20" + this.filteredSsn;
        }
      }

      return this.filteredSsn;
    },
    placeholder() {
      return this.$t("placeholder.input.ssn");
    },
    max() {
      return this.century ? 13 : 11;
    },
    validityClass() {
      return {
        "border-2": this.valid === true,
        "border-green-dark": this.valid === true,
        "bg-green-lightest": this.valid === true,
        "border-red-dark": this.valid === false,
        "bg-red-lightest": this.valid === false,
      };
    },
  },
};
</script>
