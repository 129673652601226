import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faBed,
  faTv,
  faPills,
  faTshirt,
  faShower,
  faKiss,
  faLaptop,
  faBookOpen,
  faUtensils,
  faHome,
  faPhone,
  faBuilding,
  faBicycle,
  faShoppingCart,
  faUserMd,
  faDumbbell,
  faCheck,
  faBinoculars,
  faScroll,
  faTimes,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBed,
  faTv,
  faPills,
  faTshirt,
  faShower,
  faKiss,
  faLaptop,
  faBookOpen,
  faUtensils,
  faHome,
  faPhone,
  faBuilding,
  faBicycle,
  faShoppingCart,
  faUserMd,
  faDumbbell,
  faCheck,
  faBinoculars,
  faScroll,
  faTimes,
  faSpinner
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
