import content from './../assets/i18n/faq.js'

export default {
  state: {
    faqContent: content
  },
  mutations: {
  },
  actions: { },
  getters: {
    faqContent: state => {
        return state.faqContent[localStorage.getItem('user-locale') || 'sv']
    }
  }
}