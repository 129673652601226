import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// import Login from "../components/Login.vue";
// import LoginVerified from "../components/LoginVerified.vue";
// import Start from "../components/Start.vue";
// import AdhdStart from "../components/AdhdStart.vue";
// import History from "../components/History.vue";
// import Selftest from "../components/Selftest.vue";
// import Questionnaire from "../components/Questionnaire.vue";
// import QuestionnaireLoop from "../components/QuestionnaireLoop.vue";
// import FormView from "../components/form/FormView.vue";
// import Settings from "../components/Settings.vue";
// import Selfcare from "../components/Selfcare.vue";
// import SelfcareForm from "../components/SelfcareForm.vue";
// import Faq from "../components/Faq.vue";
// import Emergency from "../components/Emergency.vue";
// import Logout from "../components/Logout.vue";

let routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "group-base" */ "@/components/Login.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "group-base" */ "@/components/Login.vue"),
    props: { errorMsg: null },
  },
  {
    path: "/loginVerified",
    name: "loginVerified",
    component: () =>
      import(/* webpackChunkName: "group-base" */ "@/components/LoginVerified"),
  },
  {
    path: "/start",
    name: "start",
    component: () =>
      import(/* webpackChunkName: "group-base" */ "@/components/Start.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/adhdstart",
    name: "adhdstart",
    component: () =>
      import(/* webpackChunkName: "group-user" */ "@/components/AdhdStart.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/history",
    component: () =>
      import(/* webpackChunkName: "group-user" */ "@/components/History.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/selftest",
    name: "selftest",
    component: () =>
      import(/* webpackChunkName: "group-user" */ "@/components/Selftest.vue"),
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: () =>
      import(
        /* webpackChunkName: "group-user" */ "@/components/Questionnaire.vue"
      ),
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/questionnaireloop",
    name: "questionnaireloop",
    component: () =>
      import(
        /* webpackChunkName: "group-user" */ "@/components/QuestionnaireLoop.vue"
      ),
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/formview",
    name: "formview",
    component: () =>
      import(
        /* webpackChunkName: "group-user" */ "@/components/form/FormView.vue"
      ),
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "group-user" */ "@/components/Settings.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/selfcare",
    component: () =>
      import(/* webpackChunkName: "group-extra" */ "@/components/Selfcare.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/selfcareform",
    name: "selfcareform",
    component: () =>
      import(
        /* webpackChunkName: "group-user" */ "@/components/SelfcareForm.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/faq",
    component: () =>
      import(/* webpackChunkName: "group-user" */ "@/components/Faq.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/emergency",
    component: () =>
      import(/* webpackChunkName: "group-user" */ "@/components/Emergency.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "group-user" */ "@/components/Logout"),
  },
  {
    path: "/research",
    name: "research",
    component: () => import("@/components/Research"),
    meta: { requireAuth: true },
  },
  { path: "*", redirect: "/" },
];

export const router = new VueRouter({
  mode: "history",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = to.meta.requireAuth && to.meta.requireAuth == true;
  const loggedIn = localStorage.getItem("user-token");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});
