import { apiPostRequest, apiGetRequest } from "./api"; // eslint-disable-line

export default {
  state: {
    projects: [],
    consents: {},
    signSessionId: null,
  },
  mutations: {
    setProjects(state, response) {
      state.projects = response;
    },
    setConsents(state, response) {
      state.consents = response;
    },
    setSignSessionId(state, response) {
      state.signSessionId = response;
    },
  },
  actions: {
    async loadProjects({ commit }) {
      await apiGetRequest("researchprojects").then((response) => {
        commit("setProjects", response);
      });
    },
    async loadConsents({ commit }, code) {
      await apiGetRequest("consents/" + code).then((response) => {
        commit("setConsents", response);
      });
    },
    async consent({ commit }, consents) { //eslint-disable-line
      return await apiPostRequest("consent", consents)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    setSignSessionId({ commit }, sessionId) {
      commit("setSignSessionId", sessionId);
    },
    async verifyBankIdSign({ state }, data) {
      if (!state.signSessionId == data.sessionId) {
        return Promise.reject("Session mismatch");
      }

      return await apiPostRequest("verifyBankIdSign", data)
        .then((response) => {
          if (response?.response?.status === 204) {
            return Promise.reject({
              status: 204,
              message: "No result, continue polling",
            });
          }
          return Promise.resolve({
            status: 200,
            message: "Consent signed",
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  getters: {
    getProjects: (state) => state.projects,
    getConsents: (state) => state.consents,
    getSignSessionId: (state) => state.signSessionId,
  },
};
