<template>
  <div id="app" class="font-sans select-none">
    <div class="h-full flex-col bg-white" @click="closeSidebar">
      <Header />

      <router-view
        id="view"
        class="container bg-white mx-auto text-center mb-20 overflow-auto"
        :key="$route.fullPath"
      ></router-view>

      <footer
        v-if="authenticated"
        class="w-full mx-auto fixed text-center bg-grey-lighter pt-4 bottom-0 h-16 shadow-inner border-t"
      >
        <SidebarToggle />
      </footer>
    </div>

    <Sidebar v-if="authenticated" />
    <SidebarRight v-if="authenticated" />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Sidebar from "./components/base/Sidebar.vue";
import SidebarRight from "./components/base/SidebarRight.vue";
import SidebarToggle from "./components/base/SidebarToggle.vue";
import { isPast } from "date-fns";

export default {
  name: "app",
  components: {
    Header,
    Sidebar,
    SidebarRight,
    SidebarToggle,
  },
  data() {
    return {
      updateAvailable: false,
    };
  },
  mounted() {
    this.checkJwtExpiration();
    this.checkUpdate();
  },
  computed: {
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    closeSidebar() {
      if (this.$store.getters.isSidebarShown) {
        this.$store.dispatch("closeSidebar");
      }
    },
    checkJwtExpiration() {
      return this.$store
        .dispatch("checkJwtExpiration")
        .then((exp) => {
          let d = new Date(exp * 1000);
          if (isPast(d)) {
            this.$store.dispatch("logout");
          }
        })
        .catch(() => { });
    },
    checkUpdate() {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((reg) => {
          if (reg.waiting) {
            this.updateAvailable = true;

            reg.waiting.postMessage("skipWaiting");

            this.updateAvailable = false;
            window.location.reload();
          }
        });
      }
    },
  },
};
</script>

<style>
#view {
  padding-bottom: 5rem;
}
</style>
