<template>
  <div class="w-full">
    <div v-if="!editing" class="font-bold pb-2">{{ title }}</div>
    <div
      v-if="!editing && info"
      class="italic -mt-1 px-2 py-1 mb-2 rounded-lg text-sm bg-yellow-lightest"
      v-html="info"
    >
    </div>

    <div
      @click="selectOption(key)"
      class="border-b-2 border-t-2 border-l-2 border-r-2 my-1 border-grey-light py-2 w-full rounded-lg"
      v-for="(option, key) in options"
      :key="key"
    >
      <div v-if="option.info && option.info != ''">
        <div>
          <div>{{ option.label }}</div>
          <div class="text-sm italic">{{ option.info }}</div>
          <img
            class="inline float-right -mt-6"
            v-show="selected === option.value"
            src="/images/checkmark.png"
          />
        </div>
      </div>

      <div v-else>
        <span :class="selected === option.value ? '-mr-image' : ''">{{
          option.label
        }}</span>
        <img
          class="inline float-right -my-1 -ml-4 mr-2"
          v-show="selected === option.value"
          src="/images/checkmark.png"
        />
      </div>

      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    formId: String,
    id: Number,
    title: String,
    options: Array,
    answer: {
      type: [String, Number],
      default: () => null,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: this.answer ? this.answer : null,
    };
  },
  methods: {
    selectOption(key) {
      let value = this.options[key].value;
      let label = this.options[key].label;

      this.selected = value;
      this.$store
        .dispatch("answer", {
          formId: this.formId,
          id: this.id,
          value: value,
          label: label,
        })
        .then(() => {
          this.$emit("disableNext", false);
          if (this.formId == "PID5") {
            this.$emit("nextQuestion");
          }
        });
    },
  },
};
</script>