<template>
    <div class="flex mb-2 mt-2">
        <label>{{ label }}</label>
        <div @click="toggle" class="inline-block align-middle relative select-none w-12 mr-2 leading-normal">
            <input class="hidden switch-checkbox" type="checkbox" :name="id" :id="id" :checked="isOn" />
            <label class="switch-label block overflow-hidden cursor-pointer bg-white border rounded-full h-6 shadow-inner"></label>
        </div>
    </div>
</template>


<script>
export default {
    name: 'BaseToggle',
    props: {
        id: {
            type: String
        },
        label: {
            type: String
        },
        on: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isOn: this.on
        }
    },
    methods: {
        toggle () {
            this.isOn = !this.isOn
            this.isOn ? document.getElementById(this.id).setAttribute('checked', true) : document.getElementById(this.id).removeAttribute('checked')
        }
    }
}
</script>

<style scoped>
.switch-label {
    transition: background-color 0.2s ease-in;
}
.switch-label:before {
    position: absolute;
    display: block;
    background-color: white;
    top: 0;
    width: 1.4rem;
    height: 1.4rem;
    border: 1px solid #555555;
    border-radius: 100%;

    right: 50%;
    content: '';
    transition: all 0.2s ease-in;
}
.switch-checkbox:checked + .switch-label,
.switch-checkbox:checked + .switch-label:before {

}
.switch-checkbox:checked + .switch-label {
    background: green;
}
.switch-checkbox:checked + .switch-label:before {
    position: absolute;
    right: 0;
}

</style>