<template>
  <div class="w-full">
    <div v-if="!editing" class="font-bold pb-2">{{ title }}</div>
    <div
      v-if="!editing && info"
      class="italic -mt-1 px-2 py-1 mb-2 rounded rounded-lg text-sm bg-yellow-lightest"
    >
      {{ info }}
    </div>

    <div
      @click="selectOption(key)"
      class="border-t-2 border-grey-light py-2 w-full"
      v-for="(option, key) in options"
      :key="key"
    >
      <span
        :class="selected.indexOf(option.value) > -1 ? '-mr-image' : ''"
        class="pr-6"
        >{{ option.label }}</span
      >
      <img
        class="inline float-right -m-1"
        v-show="selected.indexOf(option.value) > -1"
        src="/images/checkmark.png"
      />
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseMultiSelect",
  props: {
    formId: String,
    id: Number,
    title: String,
    options: Array,
    answer: {
      type: Array,
      default() {
        return [];
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: [],
      selectedLabels: [],
    };
  },
  created() {
    this.answer.forEach((a) => {
      let tmp = this.options.find((option) => {
        return option.value === a;
      });
      if (tmp) {
        this.selected.push(tmp.value);
        this.selectedLabels.push(tmp.label);
      }
    });
  },
  methods: {
    selectOption(key) {
      let value = this.options[key].value;
      let label = this.options[key].label;
      let index = this.selected.indexOf(value);
      let exclusive = this.options[key].exclusive;

      if (index === -1) {
        if (exclusive == true) {
          this.selected.splice(0, this.selected.length);
          this.selectedLabels.splice(0, this.selectedLabels.length);
        } else {
          let exclusiveValue = this.options.find((o) => {
            return o.exclusive == true;
          });
          let exclusiveIndex = this.selected.indexOf(exclusiveValue.value);
          if (exclusiveIndex > -1) {
            this.selected.splice(exclusiveIndex, 1);
            this.selectedLabels.splice(exclusiveIndex, 1);
          }
        }
        this.selected.push(value);
        this.selectedLabels.push(label);
      } else {
        this.selected.splice(index, 1);
        this.selectedLabels.splice(index, 1);
      }

      this.$store
        .dispatch("answer", {
          formId: this.formId,
          id: this.id,
          value: this.selected,
          label: this.selectedLabels,
          type: "multi",
          exclusive: exclusive,
        })
        .then(() => {
          this.$emit("disableNext", this.selected.length > 0 ? false : true);
        });
    },
  },
};
</script>