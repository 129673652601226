import { apiPostRequest, apiGetRequest } from "./api";

export default {
  state: {
    settings: {},
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    async getSettings({ commit }) {
      await apiGetRequest("patients/settings").then((data) => {
        commit("setSettings", data);
      });
    },
    async updateSettings({ commit }, settings) {
        return await apiPostRequest("patients/settings", settings).then((data) => {
            commit("setSettings", data);
            return Promise.resolve(data);
        }).catch((error) => {
            return Promise.reject(error.response.data);
        });
    }
  },
  getters: {
    settings: (state) => state.settings,
  },
};
