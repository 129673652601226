import '../fa.config';

import Vue from 'vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import axios from 'axios'

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer: ${token}`
}

import App from './App.vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import './assets/style/app.css'
import messages from './assets/i18n/messages.js'

import './registerServiceWorker'

Vue.use(Vuex)

import appStore from './store/store'
const store = new Vuex.Store(
  appStore
)

import { router } from './router/router.js';

Vue.use(VueI18n)

const locale = localStorage.getItem('user-locale') || 'sv'

const i18n = new VueI18n({
  locale: locale,
  messages,
})

Vue.prototype.$http = axios

const requireComponent = require.context(
  './components/base/',
  true, //subfolders
  /Base[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

Vue.config.productionTip = false

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  dsn: "https://c102841dd3d5415d9a56dfc50edabe3f@o309696.ingest.sentry.io/6058089",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "friskarevardag.nu", /^\//],
    }),
  ],
  // logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  store,
  i18n,
  router,
  created: () => {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          this.$store.dispatch('logout')
          // you can also redirect to /login if needed !
        }
        throw err;
      });
    });
  },
  render: h => h(App)
}).$mount('#app')
