export default {
  sv: {
    placeholder: {
      input: {
        ssn: "Ange personnummer",
      },
    },
    button: {
      start: "Starta",
      close: "Stäng",
      startReferral: "Din livssituation",
      next: "Nästa",
      nextForm: "Nästa formulär",
      finishReferral: "Dela svar",
      selftest: "Gör självtest",
      menu: "Meny",
      update: "Uppdatera",
      home: "Min sida",
      help: {
        referral: "?",
      },
      finish: {
        OWNREFERRAL: "Dela svar",
        MADRS: "Dela svar",
        AUDIT: "Dela svar",
        DUDIT: "Dela svar",
        WHODAS36: "Dela svar",
        WHODAS12: "Dela svar",
        DSM5: "Dela svar",
        PILOTFOLLOWUP: "Dela svar",
        LPFSBF: "Dela svar",
        ASRS: "Dela svar",
        questionnaireloop: "Fler självtest",
        PID5: "Dela svar",
        RAADS14: "Dela svar",
        RAADS80: "Dela svar",
        CORE10: "Dela svar",
        COREOM: "Dela svar",
        PCL5: "Dela svar",
      },
      backtoselftest: "Fortsätt",
    },
    login: {
      header: "Vuxenpsykiatrins öppenvård",
      bankid: "Logga in med BankID",
      siths: "Logga in med SITHS-kort",
      info: "Logga in för att följa ditt ärende",
      provider:
        "Levereras av Carmona AB på uppdrag av Vuxenpsykiatrins öppenvård",
      sithsinfo: "Logga in för att se och hantera inkomna patienter",
      error: {
        unauthorized:
          "Kunde inte logga in. För att kunna logga in måste du vara registrerad på en mottagning.",
        eidentity: "Inloggningen misslyckades eller avbröts.",
      },
    },
    routes: {
      home: "Min sida",
      questionnaire: "Formulär",
      history: "Min historik",
      emergency: "Akut kontakt",
      selfcare: "Egenvård",
      selftest: "Självtest",
      settings: "Inställningar",
      faq: "Frågor och svar",
      login: "Logga in",
      startReferral: "Din livssituation",
      logout: "Logga ut",
      pilotfollowup: "Dina synpunkter",
      research: "Forskningsprojekt",
    },
    settings: {
      language: "Språk",
      consent: "Samtycke till SMS-påminnelser",
      notifications: "Tillåt notifieringar",
      phonenumber: "Mobilnummer",
    },
    info: {
      logout: "Loggar ut...",
    },
    stats: {
      weekly: {
        referrals: "nya svar",
        updates: "kompletterade svar",
      },
      overall: {
        monthly: "Senaste 12 mån.",
        weekly: "Denna vecka",
        results: "Vårdresultat",
      },
    },
    header: {
      selfcare: `Medan du väntar på vård eller mellan besöken, finns det saker du kan göra själv för att må bättre. Att följa enkla dagliga rutiner är ett sätt.<br /><br />
            I nästa vy ser du 16 symboler som står för vanliga aktiviteter. De är värda olika poäng, beroende på ansträngning.<br /><br />
            Räkna vad du klarar av att göra under dagen och sätt upp mål för några dagar framåt.
            `,
      selftest:
        "Här kan du kan göra ett av våra självtest. De hjälper dig bedöma hur olika faktorer påverkar din hälsa.",
    },
    form: {
      namedscores: {
        inadvertency: "Ouppmärksamhet",
        hyperactivity: "Hyperaktivitet",
      },
      loop: {
        finish: "Tack nu är du färdig, välkommen till besöket.",
      },
    },
  },
  en: {
    placeholder: {
      input: {
        ssn: "Enter SSN",
      },
    },
    button: {
      start: "Start",
      close: "Close",
      startReferral: "Start self referral",
      next: "Next",
      nextForm: "Next form",
      finishReferral: "Send self referral",
      selftest: "begin self test",
      menu: "Menu",
      update: "Update",
      home: "My home",
      help: {
        referral: "What is a self referral?",
      },
      finish: {
        OWNREFERRAL: "Send",
        MADRS: "Send",
        AUDIT: "Send",
        DUDIT: "Send",
        WHODAS36: "Send",
      },
    },
    login: {
      header: "Psychiatric care",
      bankid: "Log in with BankID",
      siths: "Log in with SITHS-card",
      info: "Log in to fill out self referral and follow up on your submissions",
      provider: "Provided by Carmona AB for Vuxenpsykiatrins öppenvård",
      sithsinfo: "Log in to view and handle self-referrals",
      error: {
        unauthorized:
          "To be able to login, you must be registered by a unit in Vuxenpsikiatrins öppenvård",
      },
    },
    routes: {
      home: "My home",
      questionnaire: "Form",
      history: "My history",
      emergency: "Emergency contact",
      selfcare: "Self care",
      selftest: "Self-tests",
      settings: "Settings",
      faq: "FAQ",
      login: "Log in",
      startReferral: "Start self referral",
      logout: "Log out",
    },
    settings: {
      language: "Language",
      consent: "Consent",
      notifications: "Allow notifications",
    },
    info: {
      logout: "Logging out...",
    },
    stats: {
      weekly: {
        referrals: "new referrals",
        updates: "updated referrals",
      },
      overall: {
        monthly: "Last 12 months",
        weekly: "This week",
        results: "Care results",
      },
    },
  },
};
