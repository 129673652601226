/* eslint-disable no-console */
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}sw.js`, {
    ready() {
      // console.log(
      //   `${process.env.BASE_URL}` +
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'
      // )
    },

    cached() {
      // console.log('Content has been cached for offline use.')
    },
    updated() {
      // console.log('Please refresh now!.')
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.')
    },
    error() {
      // console.error('Error during service worker registration:', error)
    },
    registered() {
      // console.log('registered');
    },
  })

}
