<template>
  <div class="w-full">
    <div v-if="!editing" class="font-bold pb-2">{{ title }}</div>
    <div
      v-if="!editing && info"
      class="italic -mt-1 px-2 py-1 mb-2 rounded-lg text-sm bg-yellow-lightest"
    >
      {{ info }}
    </div>

    <div
      @click="selectOption(key)"
      class="border-t-2 border-grey-light py-2 w-full"
      v-for="(option, key) in options"
      :key="key"
    >
      <div v-if="option.info && option.info != ''">
        <div>
          <div>{{ option.label }}</div>
          <div class="text-sm italic">{{ option.info }}</div>
          <img
            class="inline float-right -mt-6"
            v-show="selected === option.value"
            src="/images/checkmark.png"
          />
        </div>
      </div>

      <div v-else>
        <span :class="selected === option.value ? '-mr-image' : ''">{{
          option.label
        }}</span>
        <img
          class="inline float-right -m-1"
          v-show="selected === option.value"
          src="/images/checkmark.png"
        />
      </div>

      <div class="clearfix"></div>
    </div>

    <transition name="slide">
      <div
        v-if="selected >= mark.threshold"
        class="border-t-2 border-grey-light py-2 w-full bg-yellow-lightest"
      >
        <span>{{ mark.label }}</span>
        <button
          @click="setMark(false)"
          type="button"
          class="bg-white border rounded px-2 py-1 ml-1 hover:bg-grey-light outline-none"
          :class="{ 'text-white bg-blue': marked === false }"
        >
          Nej
        </button>
        <button
          @click="setMark(true)"
          type="button"
          class="bg-white border rounded px-2 py-1 ml-1 hover:bg-grey-light outline-none"
          :class="{ 'text-white bg-blue': marked === true }"
        >
          Ja
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseSelectWithMark",
  props: {
    formId: String,
    id: Number,
    title: String,
    options: Array,
    answer: {
      type: [String, Number],
      default: () => null,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    },
    mark: {
      type: Object,
      default() {
        return { label: "", threshold: 100 };
      },
    },
    isMarked: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected: this.answer ? this.answer : null,
      selectedLabel: null,
      marked: this.isMarked !== null ? this.isMarked : null,
    };
  },
  methods: {
    setMark(v) {
      this.marked = v;
      this.dispatchAnswer();
    },
    selectOption(key) {
      let value = this.options[key].value;
      let label = this.options[key].label;

      this.selected = value;
      this.selectedLabel = label;
      // console.log(value, this.mark.threshold, value < this.mark.threshold)
      this.marked = null; //reset marked on all changes
      if (value < this.mark.threshold) {
        this.dispatchAnswer();
      } else {
        this.$emit("disableNext", true);
      }
    },
    dispatchAnswer() {
      let l = this.options.filter((d) => d.value === this.selected)[0].label;
      this.$store
        .dispatch("answer", {
          formId: this.formId,
          id: this.id,
          value: this.selected,
          label: l,
          marked: this.marked,
          type: "singlemarked",
        })
        .then(() => {
          this.$emit("disableNext", false);
        });
    },
  },
};
</script>

<style type="text/css" scoped>
.slide-enter-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
