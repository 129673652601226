import Vue from "vue";
import userStore from "./user";
import uiStore from "./ui";
import formStore from "./form";
import systemStore from "./system";
import researchStore from "./research";
import settings from "./settings";

// import asyncCall from './async-utils'
import * as types from "./mutation-types";

// const mutationTypes = {
//     SUCCESS: 'GET_INFO_SUCCESS',
//     FAILURE: 'GET_INFO_FAILURE',
//     PENDING: 'GET_INFO_PENDING'
// }

const state = {
  info: {},
};

const mutations = {
  [types.GET_INFO_ASYNC.PENDING](state) {
    Vue.set(state.info, types.GET_INFO_ASYNC.loadingKey, true);
    Vue.set(state.info, [types.GET_INFO_ASYNC.errorKey], null);
  },

  [types.GET_INFO_ASYNC.SUCCESS](state, info) {
    state.info[types.GET_INFO_ASYNC.loadingKey] = false;
    Vue.set(state.info, [types.GET_INFO_ASYNC.stateKey], info);
  },

  [types.GET_INFO_ASYNC.FAILURE](state, error) {
    state.info[types.GET_INFO_ASYNC.loadingKey] = false;
    Vue.set(state.info, [types.GET_INFO_ASYNC.stateKey], null);
    Vue.set(state.info, [types.GET_INFO_ASYNC.errorKey], error);
  },
};

export default {
  state,
  mutations,
  getters: {
    pendingAsyncCall: (state) => state.info.apiPending,
  },
  modules: {
    user: userStore,
    ui: uiStore,
    form: formStore,
    system: systemStore,
    research: researchStore,
    settings: settings,
  },
};
