<template>
    <button type="button" class="block w-full max-w-xs rounded-lg px-4 py-3 text-center text-lg tracking-wide" :class="stateClass" :disabled="disabled">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        stateClass () {
            return this.disabled ? 'text-white bg-grey': 'text-white bg-green'
        }
    }
}
</script>

