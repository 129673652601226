<template>
  <div class="w-full text-center bg-white py-3">
    <!-- <div v-if="pendingAsyncCall" class="">
      <div class="load-bar">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </div> -->

    <img :src="getLogo()" class="inline" alt="friskarevardag-logo" />
  </div>
</template>

<script>
export default {
  name: "Header",
  computed: {
    // pendingAsyncCall() {
    //   return this.$store.getters.pendingAsyncCall;
    // },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    getLogo() {
      let name = "logo-rh-psyk-sm";
      switch (this.userInfo.applicationName) {
        case "trana":
          name = "logo-trana-sm";
          break;
        case "psykiatri":
        case "regionhalland":
          name = "logo-rh-psyk";
          break;
        default:
          name = "logo-rh-psyk-sm";
          break;
      }
      return require(`@/assets/images/${name}.png`);
    },
  },
};
</script>
