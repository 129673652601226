<template>
  <div class="w-auto flex">
    <div class="flex flex-col text-center">
      <div class="-mb-1">
        <button>
          <img :src="imageSource" class="inline" width="20px" height="20px" />
        </button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSimpleButton",
  props: {
    icon: {
      type: String,
      default: "close.png",
    },
  },
  computed: {
    imageSource() {
      return `/images/${this.icon}`;
    },
  },
};
</script>