<template>
  <div>
    <div v-if="!editing" class="font-bold pb-2">{{ title }}</div>
    <div
      v-if="!editing && info"
      class="italic -mt-1 px-2 py-1 mb-2 rounded-lg text-sm bg-yellow-lightest"
    >
      {{ info }}
    </div>

    <input
      type="number"
      :name="id"
      @blur="blur"
      :id="id"
      v-model="numberanswer"
      :min="min"
      :max="max"
      class="rounded-t rounded-l rounded-r border border-grey-light px-2 py-1 text-left text-xl w-full"
    />
    <div>{{ min }} - {{ max }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseNumber",
  props: {
    formId: String,
    id: Number,
    title: String,
    answer: String,
    min: Number,
    max: Number,
    editing: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      numberanswer: this.answer,
    };
  },
  watch: {
    numberanswer: {
      immediate: false,
      handler(value) {
        this.$emit(
          "disableNext",
          value >= this.min && value <= this.max && value != "" ? false : true
        );
      },
    },
  },
  methods: {
    blur(evt) {
      let value = evt.target.value;

      this.$store
        .dispatch("answer", {
          formId: this.formId,
          id: this.id,
          value: value,
          type: "number",
          label: "",
        })
        .then(() => {
          this.$emit(
            "disableNext",
            value >= this.min && value <= this.max && value != "" ? false : true
          );
        });
    },
  },
};
</script>
