<template>
  <div
    class="sidebar-left fixed top-0 left-0 bg-white shadow-lg w-2/3 md:w-1/3 max-w-lg h-screen overflow-y-auto"
  >
    <div class="mx-2 px-2 flex flex-col h-full">
      <div class="flex-grow">
        <p class="text-grey text-xl pt-8 pb-2">{{ userFullname }}</p>

        <ul class="my-2 text-lg font-bold">
          <li class="py-3">
            <router-link
              to="/history"
              :class="isCurrentRoute('/history')"
              class="block no-underline hover:text-grey"
              >{{ $t("routes.history") }}</router-link
            >
          </li>
          <li class="py-3">
            <router-link
              :to="patientStartRoute"
              :class="isCurrentRoute('/start')"
              class="block no-underline hover:text-grey"
              >{{ $t("routes.startReferral") }}</router-link
            >
          </li>
          <li class="py-3">
            <router-link
              to="/emergency"
              :class="isCurrentRoute('/emergency')"
              class="block no-underline hover:text-grey"
              >{{ $t("routes.emergency") }}</router-link
            >
          </li>
          <li class="py-3">
            <router-link
              to="/selftest"
              :class="isCurrentRoute('/selftest')"
              class="block no-underline hover:text-grey"
              >{{ $t("routes.selftest") }}</router-link
            >
          </li>
          <li class="py-3">
            <router-link
              :to="{
                name: 'research',
              }"
              :class="isCurrentRoute('/research')"
              class="no-underline hover:text-grey"
              >{{ $t("routes.research") }}</router-link
            >
          </li>
          <li class="py-3">
            <router-link
              :to="{
                name: 'settings',
              }"
              :class="isCurrentRoute('/settings')"
              class="no-underline hover:text-grey"
              >{{ $t("routes.settings") }}</router-link
            >
          </li>
          <li class="py-3 text-grey font-normal">
            <router-link to="/faq" class="no-underline hover:text-grey">{{
              $t("routes.faq")
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="text-center">
        <ul class="text-lg">
          <li class="pb-4 font-bold">
            <router-link
              to="/logout"
              class="no-underline text-grey-darkest hover:text-grey"
              >{{ $t("routes.logout") }}</router-link
            >
          </li>
        </ul>
      </div>

      <BaseFlatButton @click.native="close" type="left" class="mb-4">
        <path
          d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"
        ></path>
        {{ $t("button.close") }}
      </BaseFlatButton>

      <div class="text-xs font-mono text-blue-light px-1 py-1 mb-10">
        {{ version }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  mounted() {
    this.$el.style.display = "none";
    this.$el.style.left = `-${this.$el.offsetWidth + 10}px`;
  },
  computed: {
    version() {
      //eslint-disable-next-line
      return APPLICATION_VERSION;
    },
    open() {
      return this.$store.getters.isSidebarOpen;
    },
    userFullname() {
      return (
        this.$store.getters.userInfo.first_name +
        " " +
        this.$store.getters.userInfo.last_name
      );
    },
    patientStartRoute() {
      return {
        name:
          this.$store.getters.userInfo.patienttype === "adhd"
            ? "adhdstart"
            : "start",
      };
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("closeSidebar");
    },
    open: function (open) {
      if (open) {
        this.$el.style.display = "block";
        this.$el.style.left = "0";
        this.$store.dispatch("setSidebarShown");
      } else {
        this.$el.style.left = `-${this.$el.offsetWidth + 10}px`;
      }
    },
  },
  methods: {
    isCurrentRoute(to) {
      return this.$route.path === to ? "text-blue" : "text-grey-darkest";
    },
    close() {
      this.$store.dispatch("closeSidebar");
    },
  },
};
</script>

<style>
.sidebar-left {
  transition: 0.3s;
}
</style>
