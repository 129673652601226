import { apiPostRequest, apiGetRequest } from "./api";

export default {
  state: {
    status: null,
    currentform: null,
    currentanswers: null,
    formresult: null,
    answers: {},
    selftestformlist: null,
    followupforms:
      localStorage.getItem("followupforms") !== null
        ? JSON.parse(localStorage.getItem("followupforms"))
        : [],
    completedFollowupForms: []
  },
  mutations: {
    formRequest(state) {
      state.status = "loading";
    },
    formRequestError(state) {
      state.status = "error";
    },
    formRequestComplete(state) {
      state.status = "complete";
    },
    answer(state, answer) {
      let name = answer.formId;
      let id = answer.id;
      let value = answer.value;
      let label = answer.label;
      let type = answer.type ? answer.type : null;
      let marked = answer.marked !== null ? answer.marked === true : null;

      if (!state.answers[name]) {
        state.answers[`${name}`] = {};
      }

      state.answers[name][id] = {
        value: value,
        label: label,
        type: type,
        marked: marked
      };
    },
    resetStore(state, formId) {
      state.answers[formId] = null;
    },
    setSelftestformlist(state, data) {
      state.selftestformlist = data;
    },
    setCurrentForm(state, data) {
      state.currentform = data;
    },
    setCurrentAnswers(state, data) {
      state.currentanswers = data;
    },
    setFormresult(state, data) {
      state.formresult = data;
    },
    setFollowupForms(state, data) {
      let forms = data.map(f => {
        return {
          title: f.title,
          code: f.code,
          complete: false
        };
      });
      state.followupforms = forms;
      localStorage.setItem("followupforms", JSON.stringify(forms));
    },
    markFollowUpFormComplete(state, formCode) {
      if (state.completedFollowupForms.indexOf(formCode) == -1) {
        state.completedFollowupForms.push(formCode);
      }

      let index = state.followupforms.find(f => f.code === formCode);
      if (index) {
        index.complete = true;

        localStorage.setItem(
          "followupforms",
          JSON.stringify(state.followupforms)
        );
      }
    }
  },
  actions: {
    loadSelftestFormList({ commit }) {
      return apiGetRequest("forms").then(data => {
        commit("setSelftestformlist", data);
        return data;
      });
    },
    loadForm({ commit }, id) {
      commit("setFormresult", null);

      commit("resetStore", id);

      apiGetRequest(`forms/${id}`).then(data => {
        commit("setCurrentForm", data);
      });
    },
    loadFormAnswers({ commit }, responseId) {
      apiGetRequest(`forms/answers/${responseId}`).then(data => {
        commit("setCurrentAnswers", data);
      }).catch((e) => {
        console.log(e);
      });
    },
    answer({ commit }, answer) {
      commit("answer", answer);
    },
    async submitForm({ commit, state }, { formId, responseId }) {
      var data = {
        code: formId,
        answers: []
      };
      if (responseId) {
        data["response_id"] = responseId;
      }
      let answers = state.answers[formId];

      Object.keys(answers).forEach(id => {
        let answer = answers[id];
        if (answer.type === "open") {
          data.answers.push({
            id: id,
            text: answer.value
          });
        } else if (answer.type === "number") {
            data.answers.push({
              id: id,
              text: answer.value
            })
        } else if (answer.type === "multi") {
          data.answers.push({
            id: id,
            values: answer.value
          });
        } else if ((answer.type === "singlemarked")) {
          data.answers.push({
            id: id,
            values: [answer.value],
            marked: answer.marked !== null ? answer.marked : null
          });
        } else {
          data.answers.push({
            id: id,
            values: [answer.value]
          });
        }
      });

      let r = await apiPostRequest("forms/responses", data).then(d => {
        commit("resetStore", formId);
        commit("setFormresult", d);
        if (formId !== "OWNREFERRAL") {
          commit("markFollowUpFormComplete", formId);
        }
        return d;
      });

      return r;
    },
    unsetCurrentForm({ commit }) {
      commit("setCurrentForm", null);
    },
    setFollowupForms({ commit }, followupForms) {
      commit("setFollowupForms", followupForms);
    }
  },
  getters: {
    getAnswerByFormIdAndId: state => (formId, id) => {
      if (
        !state.answers[formId] ||
        !state.answers[formId][id] ||
        state.answers[formId][id].value.length == 0
      ) {
        return "";
      }

      return state.answers[formId][id];
    },
    formRequestStatus: state => state.status,
    selftestformlist: state => state.selftestformlist,
    followupforms: state => state.followupforms,
    currentform: state => state.currentform,
    currentanswers: state => state.currentanswers,
    formresult: state => state.formresult,
    completedFollowupForms: state => state.completedFollowupForms,
    answers: state => state.answers
  }
};
