export default {
    state: {
        sidebarOpen: false,
        sidebarShown: false,
        sidebarRightOpen: false,
        sidebarRightShown: false,
    },
    mutations: {
        setSidebarShown() {
            // state.sidebarShown = true
        },
        setSidebarRightShown() {
            // state.sidebarRightShown = true
        },
        closeSidebar(state) {
            state.sidebarOpen = false
            state.sidebarShown = false
        },
        closeSidebarRight(state) {
            state.sidebarRightOpen = false
            state.sidebarRightShown = false
        },
        toggleSidebar(state) {
            state.sidebarOpen = !state.sidebarOpen
        },
        toggleSidebarRight(state) {
            state.sidebarRightOpen = !state.sidebarRightOpen
        }
    },
    actions: {
        setSidebarShown({ commit }) {
            commit('setSidebarShown')
        },
        setSidebarRightShown({ commit }) {
            commit('setSidebarRightShown')
        },
        closeSidebar({ commit }) {
            commit('closeSidebar')
        },
        closeSidebarRight({ commit }) {
            commit('closeSidebarRight')
        },
        toggleSidebar({ commit }) {
            commit('toggleSidebar')
        },
        toggleSidebarRight({ commit }) {
            commit('toggleSidebarRight')
        }
    },
    getters: {
        isSidebarOpen: state => state.sidebarOpen,
        isSidebarShown: state => state.sidebarShown,
        isSidebarRightOpen: state => state.sidebarRightOpen,
        isSidebarRightShown: state => state.sidebarRightShown,
    }
}
