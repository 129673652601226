<template>
  <div class="w-full flex-col">
    <button class="-mt-2" @click="handleClick">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 50 50"
        version="1.1"
        width="28px"
        height="28px"
      >
        <path
          style=""
          d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z "
        />
      </svg>
    </button>
    <span class="block -mt-1 text-sm">{{ $t("button.menu") }}</span>
  </div>
</template>

<script>
export default {
  name: "SidebarToggle",
  methods: {
    handleClick() {
      this.$store.dispatch("toggleSidebar");
    },
  },
};
</script>